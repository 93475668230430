.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 36px;

  &__links {
    display: flex;
    gap: 24px;
    list-style: none;
    color: $text;
    cursor: pointer;
  }

  &__link {
    &-active {
      color: $brand-1;
    }

    &-pending {
      color: $text;

      &:hover {
        color: $brand-3;
      }
    }
  }

  &__logo {
    width: 20px;
    height: 20px;
    background-color: $brand-1;
    border-radius: 100px;
  }
}
