.blog {
  padding-top: 72px;
  padding-bottom: 128px;
  margin: 0px 32px;
}

.blog-post {
  // max-width: 400px;

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    padding: 24px 0px;
  }

  &__image {
    width: 100%;
    height: 225px;
    object-fit: cover;
    clip-path: border-box;
  }

  &__image-empty {
    width: 100%;
    height: 225px;
    background-color: $brand-1;
  }
}

.no-blogs {
  width: 100%;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    font-style: italic;
    font-weight: 400;
  }
  svg {
    width: 50px;
    height: 50px;
    fill: $brand-4;
  }
}

@media only screen and (max-width: 672px) {
  .blog {
    padding-top: 32px;
    padding-bottom: 72px;
    margin: revert;

    &-description {
      font-size: 16px;
    }
  }
  .blog-post {
    padding: 12px 0px;

    p {
      font-size: 16px;
    }

    &__title {
      margin: 4px 0px;
    }

    &__date {
      margin: 8px 0px;
    }

    &__description {
      margin: 0;
    }

    &__container {
      display: block;
      padding: revert;
    }
  }

  .no-blogs__text-wrapper {
    width: 50%;
    text-align: center;
  }
}
