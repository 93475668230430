@import "./utlities/_variables";
@import "./pages/style.scss";
@import "./components/navigation";
@import "./components/project";

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&family=Volkhov:ital@0;1&display=swap");

html {
  background-color: $background-1;
}

body {
  color: $text;
  font-family: "Nunito", sans-serif;
  font-style: normal;
}

a {
  text-decoration: none;
  color: $brand-3;
}

h2 {
  font-family: "Volkhov", sans-serif;
  font-style: italic;
  font-weight: 700;
}

.page-layout {
  margin: 0px 36px;
}

.goon {
  clip-path: circle();
  object-fit: cover;
  border-radius: 1000px;
  width: 100%;
  max-width: 500px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  fill: $fade;
  color: $fade;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 15%;
  }

  &__socials a {
    line-height: 1.5px;
  }

  // &__bar {
  //   background-color: $text;
  //   height: 24px;
  //   width: 100%;
  //   position: absolute;
  //   left: 0;
  // }
}

// Standard Width
@media only screen and (min-width: 1056px), (max-width: 1800px) {
  .App {
    display: flex;
  }
}

// Mobile or Large Screen
@media only screen and (max-width: 1056px), (min-width: 1800px) {
}

// Mobile only
@media only screen and (max-width: 672px) {
  .page-layout {
    margin: 0px 24px;
  }

  p {
    font-size: 24px;
  }

  .footer__bar {
    height: 12px;
  }

  .landing__mandarin {
    font-size: 42px;
    padding-top: 20%;
  }

  .landing {
    &__description {
      max-width: 80%;
    }
    &__projects-grid {
      display: grid;
      grid-template-columns: none;
    }
  }
}
